import { Component } from 'react';

import NavBar from '../nav';
import Footer from '../footer';

class Shop extends Component {
  render(){
  return (
   <>
   <NavBar/>
<section className='bg-black'>
   
<h2 className='text-white  mt-28 ml-96  font-semibold  capitalize'>OUR VISION</h2>
<p className='text-gray-500 text-1xl mt-8 '>Mango Telecom Ltd (hereinafter referred to as Mango) was founded in 2018 in Kigali, Rwanda. Mango is a licensed Internet Service Provider (ISP)</p>
<p className='text-gray-500 text-1xl  mt-2'>that is incorporated under the laws and regulations of the Republic of Rwanda. Our company is engaged in the business of providing advanced</p>
<p className='text-gray-500 text-1xl  mt-2'>information and Communication Technology (“ICT”) services based on 4G LTE. With advanced 4G network, mango telecom has provided low-cost</p>
<p className='text-gray-500 text-1xl  mt-2 '>and high-value network services for numerous clients engaged in real estate, tourism, decoration, building materials, sports, automobiles, training and</p>
<p className='text-gray-500 text-1xl  mt-2 '>services in Rwanda, and has won the consistent praise from customers</p>
<h2 className='text-white  mt-28 mb-14 ml-96 font-semibold  capitalize'>OUR STORE</h2>
</section>
<div className='grid grid-cols-2 gap-3'>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Remera</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Kiyovu</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/15205052573742680748055c4-bc67-4b51-9d2d-b537ac766e26..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center supermarket</span>
            <p class=" text-gray-500 ml-1 ">1st Floor,T2000 House</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0783983060</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/17547117558396707fa1743e5-ae13-4427-b926-7b97284e1c2b..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Kimironko</span>
            <p class=" text-gray-500  ml-1">
Kimironko sector, Gasabo District, near Urwego BANK</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0780154059</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/79769000957080711ccbc140f-b944-4377-9f85-87c78a0bcbc8..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Nyamirambo</span>
            <p class=" text-gray-500 ml-1 ">Kuri 40, KN2 Ave, Nyarugenge District, Kigali</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0789635177</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/17547634118058086be1656ed-4b3c-4474-bb3e-992cb390ebcf..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Gisozi</span>
            <p class=" text-gray-500  ml-1">2nd Floor, Companion(Aparwa) House, KG 693, Gasabo district, Kigal</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0784949114</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Kabuga</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Rwamagana</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Kayonza</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Nyagatare</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Musanze</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Rubavu I</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Rusizi</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Huye I</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Nyanza</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Nyamagabe</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Rusomo</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Kabarore</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Nyamata</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Gikondo</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center sonatube</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Giporoso</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G B&A Department</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G C&D Department</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Nyabugogo II</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Muhanga</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Kirehe</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Ruhango</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Karongi</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Kagugu</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Gicumbi</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0788393336</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Masaka</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
     
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Nyaraturama</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-4  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/21911320600519755067bf831-236e-45f0-8523-30ab6dc3f152..jpeg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black mr-0">Mango 4G center Rwimiyaga</span>
            <p class=" text-gray-500 ml-1 ">Gasabo District, Kigali near LANDO HOTEL</p>
            <p class=" text-gray-500  ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500  ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: close</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500 ml-1">0780547885</p>
            
        </div>
    </div>
    <div class=" max-w-xs p-2  flex flex overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-gray-800">
        <img class="object-cover w-16 h-12 " src="https://mango-tel.oss-eu-central-1.aliyuncs.com/mango4g/797921076247517239a699249-9c81-4b4b-a724-877af180fca0..jpg" alt="avatar"/>

        <div class="px-2">
            <span class=" text-black dark:text-black-200 mr-0">Mango 4G center Ruyenzi</span>
            <p class=" text-gray-500  ml-1">Grand Pension Plazza House, KN 4 Ave Nyarugenge District, Kigali</p>
            <p class=" text-gray-500 ml-1">Monday-Friday: 8am to 8pm</p>
            <p class=" text-gray-500 ml-1">Saturday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Sunday: 10am to 8pm</p>
            <p class=" text-gray-500  ml-1">Umuganda: 1pm to 8pm</p>
            <p class=" text-gray-500  ml-1">0785246994</p>
            
        </div>
    </div>
     
</div>
         
<Footer/>
   
   </>
  );
}
}

export default Shop;
